<template>
  <div class="main">
    <div class="main-left">
      <img
        @click="tohomepage"
        src="../../../assets/image/common/logo.svg"
        alt=""
      />
      <span
        :class="{ active: $store.state.navActive == 1 }"
        @click="navClick(1)"
      >
        <router-link :to="{ path: 'homePage' }">首页</router-link>
      </span>
      <span
        :class="{ active: $store.state.navActive == 2 }"
        @click="navClick(2)"
      >
        <router-link v-if="$store.state.islogin === 'true'" :to="{ path: 'reportingGuidelines' }"
          >最新指南</router-link
        >
        <router-link v-else :to="{ path: '/pc/loginroot/login' }"
        >最新指南</router-link
        >
      </span>
      <!-- <span
        :class="{active:$store.state.navActive==3}"
        @click="navClick(3)"
      >
        <router-link :to="{path:'politicsInTheTender'}">政采招标</router-link>
      </span> -->
      <span
        :class="{ active: $store.state.navActive == 6 }"
        @click="navClick(6)"
      >
        <router-link :to="{ path: 'mySubscription' }">我的订阅</router-link>
      </span>
      <span
        :class="{ active: $store.state.navActive == 5 }"
        @click="navClick(5)"
      >
        <router-link :to="{ path: 'depthOfTheArticle' }">深度文章</router-link>
      </span>
      <!-- <span
        :class="{ active: $store.state.navActive == 4 }"
        @click="navClick(4)"
      >
        <router-link :to="{ path: 'scientificResearchProject' }"
          >立项数据</router-link
        >
      </span> -->
    </div>
    <div class="header-right">
      <img @click="toGuide" class="header-search" src="../../../assets/image/common/header-search.svg" alt="">
      <div class="loginright" v-if="$store.state.islogin === 'true'">
        <img
          v-if="$store.state.ispro === 'false'"
          @click="tovip"
          src="../../../assets/image/升级会员.png"
          alt=""
        />
        <div v-if="$store.state.ispro === 'false'" class="main-right successlogin" @mouseenter="mouseenterUserImg()">
          <img src="../../../assets/image/登录成功.png" alt="" />
          <div
            class="userList"
            v-show="isshowUserlist"
            @mouseleave="mouseleaveUserImg()"
          >
            <div class="userList-item" id="" @click="tomyCollectionarticles()">
              我的收藏
            </div>
            <div class="userList-item" id="" @click="tomyRecentInformation()">
              近期浏览
            </div>
            <div class="userList-item" id="" @click="toHaibao()">
              电子发票
            </div>
            <div class="userList-item" id="loginout" @click="loginoouClick()">
              退出登录
            </div>
          </div>
        </div>
        <div v-if="$store.state.ispro === 'true'" class="main-right successlogin" @mouseenter="mouseenterUserImg()">
          <img src="../../../assets/image/登录成功1.png" alt="" class="yinyingxuanran"/>
          <div
            class="userList z_login"
            v-show="isshowUserlist"
            @mouseleave="mouseleaveUserImg()"
          >
            <div class="title">
              <span>VIP</span>
              <div class="span">PRO会员</div>
            </div>
            <div class="userList-item li active">
              {{$store.state.vip_time_new}}到期
            </div>
            <div class="userList-item li" id="" @click="tomyCollectionarticles()">
              我的收藏
            </div>
            <div class="userList-item li" id="" @click="tomyRecentInformation()">
              近期浏览
            </div>
            <div class="userList-item li" id="" @click="toHaibao()">
              电子发票
            </div>
            <div class="userList-item li" id="loginout" @click="loginoouClick()">
              退出登录
            </div>
          </div>
        </div>
      </div>

      <div class="main-right" v-else>
        <span @click="tologin">登录</span>
        <span class="active" @click="toRegistered">免费注册</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 导航栏标签状态控制变量
      navActive: "",
      // 控制用户列表显示变量
      isshowUserlist: false,
    };
  },

  methods: {
    // 跳转到最新指南&定位搜索
    toGuide() {
      this.$router.push({
        path: "/pc/content/reportingGuidelines",
        query: {
          opType: 'search' // 操作类型，搜索
        }
      })
    },
    tologin: function () {
      this.$router.push({
        path: "/pc/loginroot/login",
      });
    },
    // 注册
    toRegistered: function () {
      this.$router.push({
        path: "/pc/loginroot/registered",
      });
    },
    navClick: function (val) {
      this.$data.navActive = val;
      localStorage.setItem("navActive", val);
      this.$store.commit("setnavActive", val);
    },
    // 鼠标移动到用户头像显示用户列表
    mouseenterUserImg() {
      this.$data.isshowUserlist = true;
    },
    // 鼠标移出用户头像隐藏用户列表
    mouseleaveUserImg() {
      this.$data.isshowUserlist = false;
    },
    // 退出登录点击事件
    loginoouClick() {
      localStorage.setItem("setLoginStatus", false);
      localStorage.removeItem("usermessage");
      this.$store.commit("setLoginStatus", false);
      console.log(this.$route);
    },
     // 跳转到我收藏的文章
    tomyCollectionarticles() {
      this.$router.push({
        path: "myCollectionarticles",
      });
      window._czc.push(["_trackEvent", "点击", "跳转到我的收藏"]);
    },
    // 跳转到我近期浏览过的信息
    tomyRecentInformation() {
      this.$router.push({
        path: "myRecentInformation",
      });
      window._czc.push(["_trackEvent", "点击", "跳转到我近期浏览过的信息"]);
    },
    //开具发票
   toHaibao: function () {
      this.$router.push({
        path: "/pc/content/myHaibao",
      });
    },
    // 跳转到充值vip页面
    tovip() {
      this.$router.push({
        path: "/pc/viproot/vip",
      });
    },
    // 跳转到首页
    tohomepage() {
      localStorage.setItem("navActive", 1);
      this.$store.commit("setnavActive", 1);
      this.$router.push({
        path: "/pc/content/homePage",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 1200px !important;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .main-left {
    display: flex;
    justify-content: left;
    align-items: center;
    img {
      margin: 11px 90px 11px 0;
      height: 32px;
    }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px 14px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24px;
      color: #333333;
      margin-right: 18px;
      a {
        color: #333333;
      }
    }
    span.active {
      // background: #f8f8f8;
      // border-radius: 12px;
      position: relative;
      &::before{content: ""; width: 22px;
          height: 3px;
          background-color: #5e068c;
          position: absolute;
          left: 0;
          right: 0;margin: 0 auto;
          bottom: -5px;}
    }
  }
  .main-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    position: relative;
    .userList {
      border: 1px solid #d1d1d1;
      position: absolute;
      width: 120px;
      top: 41px;
      left: -25px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      // line-height: 24px;
      line-height: 2;
      text-align: center;
      overflow: hidden;
      background-color: #fff;
      opacity: 1;
      z-index: 9;
      cursor: pointer;
      .userList-item {
        padding: 0px 15px;
      }
      .userList-item:hover {
        color: #5e068c;
      }
    }
    img {
      width: 36px;
      height: 36px !important;
    }
    span {
      cursor: pointer;
      margin-left: 18px;
      padding: 8px 12px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24px;
      color: #333333;
    }
    span.active {
      background-color: #5e068c;
      border-radius: 4px;
      color: #fff;
    }
  }
  .successlogin {
    display: flex;
    flex-direction: column;
  }
  .header-right {
    display: flex;
    align-items: center;
    .header-search {
      margin-right: 32px;
      cursor: pointer;
    }
    .loginright {
      display: flex;
      align-items: center;
      img {
        height: 36px;
      }
    }
  }

}

.z_login{background: #353535 !important;
border-radius: 10px 10px 0px 0px;border: 0 !important;
.title{width: 100%;
height: 30px;
background: linear-gradient(117deg, #FFD1A7 0%, #F8E1C2 100%);
display: flex;
align-items: center;
justify-content: center;
overflow: hidden;
position: relative;
  span{position: absolute;z-index: 1;font-weight: bold;color: #FFEAD3;opacity: 0.5;    margin: 0;
    padding: 0;
    font-size: 50px;}
  .span{font-size: 14px;color: #000000;font-weight: bold;position: relative;z-index: 2;}
}
  .li{color: #fff;
    &:hover{color:#FFDEC0 !important;}
    &.active{color:#FFDEC0 !important;white-space: nowrap;}
  }
}

.yinyingxuanran{box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);border-radius: 50%;}
</style>
